import React, { useState, useEffect } from 'react';
import { PlusCircle, Edit2, Trash2, Youtube } from 'lucide-react';
import { fetchVideos, createVideo, updateVideo, deleteVideo, ApiVideo } from '../../lib/api';

interface VideosManagerProps {
  language: 'en' | 'so';
}

export function VideosManager({ language }: VideosManagerProps) {
  const [videos, setVideos] = useState<ApiVideo[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<Partial<ApiVideo> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadVideos();
  }, []);

  const loadVideos = async () => {
    try {
      setLoading(true);
      setError(null);
      const videosData = await fetchVideos();
      setVideos(videosData);
    } catch (error) {
      console.error('Error loading videos:', error);
      setError(language === 'en'
        ? 'Failed to load videos. Please try again later.'
        : 'Waa laga fursan waayey in la soo dejiyo muuqaalada. Fadlan isku day mar kale.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentVideo) return;

    try {
      setError(null);
      if (currentVideo.id) {
        await updateVideo(currentVideo.id, currentVideo);
      } else {
        await createVideo(currentVideo as Omit<ApiVideo, 'id'>);
      }
      await loadVideos();
      setIsEditing(false);
      setCurrentVideo(null);
    } catch (error) {
      console.error('Error saving video:', error);
      setError(language === 'en'
        ? 'Failed to save video. Please try again.'
        : 'Waa laga fursan waayey in la kaydiyo muuqaalka. Fadlan isku day mar kale.');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm(language === 'en'
      ? 'Are you sure you want to delete this video?'
      : 'Ma hubtaa inaad rabto inaad tirtirto muuqaalkan?')) {
      return;
    }

    try {
      setError(null);
      await deleteVideo(id);
      await loadVideos();
    } catch (error) {
      console.error('Error deleting video:', error);
      setError(language === 'en'
        ? 'Failed to delete video. Please try again.'
        : 'Waa laga fursan waayey in la tirtiro muuqaalka. Fadlan isku day mar kale.');
    }
  };

  const extractYouTubeId = (url: string): string => {
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&?]+)/);
    return match ? match[1] : '';
  };

  const getYouTubeThumbnail = (url: string): string => {
    const videoId = extractYouTubeId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : '';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          {language === 'en' ? 'Videos Manager' : 'Maamul Muuqaalada'}
        </h1>
        <button
          onClick={() => {
            setIsEditing(true);
            setCurrentVideo({
              title_en: '',
              title_so: '',
              description_en: '',
              description_so: '',
              url: ''
            });
          }}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <PlusCircle className="w-4 h-4" />
          {language === 'en' ? 'Add Video' : 'Kudar Muuqaal'}
        </button>
      </div>

      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      {isEditing && currentVideo && (
        <form onSubmit={handleSubmit} className="space-y-6 mb-8 bg-gray-50 p-6 rounded-lg">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {language === 'en' ? 'Title (English)' : 'Cinwaanka (English)'}
              </label>
              <input
                type="text"
                value={currentVideo.title_en}
                onChange={(e) => setCurrentVideo({
                  ...currentVideo,
                  title_en: e.target.value
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {language === 'en' ? 'Title (Somali)' : 'Cinwaanka (Soomaali)'}
              </label>
              <input
                type="text"
                value={currentVideo.title_so}
                onChange={(e) => setCurrentVideo({
                  ...currentVideo,
                  title_so: e.target.value
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {language === 'en' ? 'Description (English)' : 'Sharaxaada (English)'}
            </label>
            <textarea
              value={currentVideo.description_en}
              onChange={(e) => setCurrentVideo({
                ...currentVideo,
                description_en: e.target.value
              })}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {language === 'en' ? 'Description (Somali)' : 'Sharaxaada (Soomaali)'}
            </label>
            <textarea
              value={currentVideo.description_so}
              onChange={(e) => setCurrentVideo({
                ...currentVideo,
                description_so: e.target.value
              })}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {language === 'en' ? 'Video URL (YouTube)' : 'URL-ka Muuqaalka (YouTube)'}
            </label>
            <input
              type="url"
              value={currentVideo.url}
              onChange={(e) => setCurrentVideo({
                ...currentVideo,
                url: e.target.value
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
              placeholder="https://www.youtube.com/watch?v=..."
            />
          </div>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => {
                setIsEditing(false);
                setCurrentVideo(null);
              }}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {language === 'en' ? 'Cancel' : 'Jooji'}
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
            >
              {language === 'en' ? 'Save' : 'Kaydi'}
            </button>
          </div>
        </form>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {videos.map((video) => (
          <div
            key={video.id}
            className="bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="aspect-video relative">
              <img
                src={getYouTubeThumbnail(video.url)}
                alt={video.title_en}
                className="w-full h-full object-cover rounded-t-lg"
              />
              <a
                href={video.url}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity"
              >
                <Youtube className="w-12 h-12 text-white" />
              </a>
            </div>

            <div className="p-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 line-clamp-1">
                    {language === 'en' ? video.title_en : video.title_so}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 line-clamp-1">
                    {language === 'en' ? video.description_en : video.description_so}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => {
                      setIsEditing(true);
                      setCurrentVideo(video);
                    }}
                    className="p-2 text-gray-400 hover:text-blue-600"
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(video.id)}
                    className="p-2 text-gray-400 hover:text-red-600"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>

              <div className="mt-2">
                <p className="text-sm text-gray-900 line-clamp-2">
                  {language === 'en' ? video.description_en : video.description_so}
                </p>
              </div>
            </div>
          </div>
        ))}

        {videos.length === 0 && !loading && (
          <div className="col-span-3 text-center py-12">
            <p className="text-gray-500">
              {language === 'en' ? 'No videos found. Create one to get started!' : 'Muuqaalada ma lahan. Kudar mid ka bilowga!'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}