import React, { useState, useEffect } from 'react';
import { Trash2, Mail, Phone, User, MessageSquare } from 'lucide-react';
import { fetchContacts as fetchContactRequests, deleteContact, Contact as ApiContactRequest } from '../../lib/api';

interface ContactManagerProps {
  language: 'en' | 'so';
}

export function ContactManager({ language }: ContactManagerProps) {
  const [requests, setRequests] = useState<ApiContactRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadRequests();
  }, []);

  const loadRequests = async () => {
    try {
      setLoading(true);
      setError(null);
      const requestsData = await fetchContactRequests();
      setRequests(requestsData.sort((a, b) => 
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      ));
    } catch (error) {
      console.error('Error loading contact requests:', error);
      setError(language === 'en'
        ? 'Failed to load contact requests. Please try again later.'
        : 'Waa laga fursan waayey in la soo dejiyo codsiyada xiriirka. Fadlan isku day mar kale.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm(language === 'en'
      ? 'Are you sure you want to delete this contact request?'
      : 'Ma hubtaa inaad rabto inaad tirtirto codsigan xiriirka?')) {
      return;
    }

    try {
      setError(null);
      await deleteContact(id);
      await loadRequests();
    } catch (error) {
      console.error('Error deleting contact request:', error);
      setError(language === 'en'
        ? 'Failed to delete contact request. Please try again.'
        : 'Waa laga fursan waayey in la tirtiro codsiga xiriirka. Fadlan isku day mar kale.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">
          {language === 'en' ? 'Contact Requests' : 'Codsiyada Xiriirka'}
        </h1>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 gap-6">
        {requests.map((request) => (
          <div key={request.id} className="bg-white shadow-md rounded-lg p-6">
            <div className="flex justify-between items-start">
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <User className="w-5 h-5 text-gray-500" />
                  <span className="font-medium">{request.name}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <Mail className="w-5 h-5 text-gray-500" />
                  <a href={`mailto:${request.email}`} className="text-blue-500 hover:text-blue-700">
                    {request.email}
                  </a>
                </div>
                {request.phone && (
                  <div className="flex items-center space-x-2">
                    <Phone className="w-5 h-5 text-gray-500" />
                    <a href={`tel:${request.phone}`} className="text-blue-500 hover:text-blue-700">
                      {request.phone}
                    </a>
                  </div>
                )}
                <div className="flex items-start space-x-2">
                  <MessageSquare className="w-5 h-5 text-gray-500 mt-1" />
                  <p className="text-gray-700">{request.message}</p>
                </div>
                <div className="text-sm text-gray-500">
                  {language === 'en' ? 'Received: ' : 'La helay: '}
                  {new Date(request.created_at).toLocaleString(language === 'en' ? 'en-US' : 'so-SO')}
                </div>
              </div>
              <button
                onClick={() => handleDelete(request.id)}
                className="text-red-500 hover:text-red-700"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}

        {requests.length === 0 && (
          <div className="text-center py-12 bg-white shadow-md rounded-lg">
            <p className="text-gray-500">
              {language === 'en'
                ? 'No contact requests found.'
                : 'Ma jiraan codsiyada xiriir.'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}