import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || 'https://hlzewoqgystwhgtbyhkh.supabase.co';
const supabaseKey = import.meta.env.VITE_SUPABASE_SERVICE_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhsemV3b3FneXN0d2hndGJ5aGtoIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczMjIyMzExMSwiZXhwIjoyMDQ3Nzk5MTExfQ.oEPBbXosRWjTbPcE9tOsRq21ZVdznV2ZK1B5Qo3XyWY';

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true
  }
});

// Function to get public URL for an image
export const getImagePublicUrl = (path: string): string => {
  const { data: { publicUrl } } = supabase.storage
    .from('images')
    .getPublicUrl(path);
  return publicUrl;
};

// Function to upload image to Supabase Storage
export const uploadImage = async (file: File): Promise<string> => {
  try {
    // Check if user is authenticated
    const { data: { session }, error: authError } = await supabase.auth.getSession();
    if (authError || !session) {
      throw new Error('You must be logged in to upload images');
    }

    // Generate a unique file name
    const fileExt = file.name.split('.').pop();
    const fileName = `${Date.now()}-${Math.random().toString(36).substring(2)}.${fileExt}`;

    // Upload the file
    const { data, error: uploadError } = await supabase.storage
      .from('images')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false,
        contentType: file.type
      });

    if (uploadError) {
      throw uploadError;
    }

    if (!data?.path) {
      throw new Error('Upload successful but path is missing');
    }

    // Return just the path - we'll convert to public URL when needed
    return data.path;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

// Function to delete image from Supabase Storage
export const deleteImage = async (path: string) => {
  try {
    const { error } = await supabase.storage
      .from('images')
      .remove([path]);

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};
