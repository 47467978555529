import React from 'react';
import { Users, Award, Globe2 } from 'lucide-react';

interface AboutProps {
  language: 'en' | 'so';
}

const content = {
  en: {
    title: 'About Arji International',
    description: 'A Legacy of Legal Excellence',
    mission: {
      title: 'Our Mission',
      text: 'To provide accessible, high-quality legal services and education to communities worldwide, ensuring justice and legal understanding for all.',
    },
    vision: {
      title: 'Our Vision',
      text: 'To be the leading force in global legal services, bridging cultures and communities through exceptional legal expertise.',
    },
    values: {
      title: 'Our Values',
      items: [
        'Excellence in Service',
        'Integrity & Ethics',
        'Cultural Sensitivity',
        'Innovation',
        'Community Focus',
      ],
    },
  },
  so: {
    title: 'Ku Saabsan Arji International',
    description: 'Dhaxal Sharci oo Heer Sare ah',
    mission: {
      title: 'Hadafkeena',
      text: 'Inaan bixino adeegyo sharci oo tayo sare leh oo la heli karo iyo waxbarasho bulshooyinka caalamka oo dhan, si loo hubiyo cadaalad iyo faham sharci oo dhammaantood.',
    },
    vision: {
      title: 'Aragtideena',
      text: 'Inaan noqono hogaamiyaha adeegyada sharciga ee caalamiga ah, isku xidhaya dhaqamada iyo bulshooyinka iyada oo loo marayo aqoon sharci oo sareysa.',
    },
    values: {
      title: 'Qiyamkeena',
      items: [
        'Adeeg Heer Sare ah',
        'Daacadnimo & Anshaxa',
        'Xassaasiyada Dhaqanka',
        'Horumar',
        'Diirada Bulshada',
      ],
    },
  },
};

export function About({ language }: AboutProps) {
  return (
    <div className="py-20">
      <div className="container mx-auto px-6">
        {/* Header */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h1 className="text-4xl font-bold mb-4">{content[language].title}</h1>
          <p className="text-xl text-gray-600">{content[language].description}</p>
        </div>

        {/* Mission, Vision, Values */}
        <div className="grid md:grid-cols-3 gap-12 mb-20">
          <div className="bg-white p-8 rounded-xl shadow-sm">
            <Users className="w-12 h-12 text-blue-600 mb-6" />
            <h2 className="text-2xl font-bold mb-4">
              {content[language].mission.title}
            </h2>
            <p className="text-gray-600">{content[language].mission.text}</p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-sm">
            <Globe2 className="w-12 h-12 text-blue-600 mb-6" />
            <h2 className="text-2xl font-bold mb-4">
              {content[language].vision.title}
            </h2>
            <p className="text-gray-600">{content[language].vision.text}</p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-sm">
            <Award className="w-12 h-12 text-blue-600 mb-6" />
            <h2 className="text-2xl font-bold mb-4">
              {content[language].values.title}
            </h2>
            <ul className="space-y-2 text-gray-600">
              {content[language].values.items.map((item, index) => (
                <li key={index} className="flex items-center gap-2">
                  <div className="w-2 h-2 bg-blue-600 rounded-full" />
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Team Image */}
        <div className="rounded-xl overflow-hidden">
          <img
            src="https://images.unsplash.com/photo-1556761175-b413da4baf72?auto=format&fit=crop&q=80"
            alt="Our Team"
            className="w-full h-[400px] object-cover"
          />
        </div>
      </div>
    </div>
  );
}