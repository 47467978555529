import React, { useState, useEffect } from 'react';
import { Youtube, AlertCircle } from 'lucide-react';
import { fetchVideos, Video } from '../lib/api';

interface VideosProps {
  language: 'en' | 'so';
}

const getYouTubeEmbedUrl = (url: string): string => {
  try {
    // Handle different YouTube URL formats
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    const videoId = match?.[2];
    
    if (videoId) {
      // Use YouTube's nocookie domain for better privacy
      return `https://www.youtube-nocookie.com/embed/${videoId}?rel=0`;
    }
    return ''; // Return empty string if not a valid YouTube URL
  } catch {
    return '';
  }
};

const Videos: React.FC<VideosProps> = ({ language }) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const data = await fetchVideos();
        setVideos(data);
        setLoading(false);
      } catch (err) {
        console.error('Error loading videos:', err);
        setError(err instanceof Error ? err.message : 'Failed to load videos');
        setLoading(false);
      }
    };

    loadVideos();
  }, []);

  if (loading) return (
    <div className="flex justify-center items-center min-h-[400px]">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center min-h-[400px] text-red-500">
      <div className="text-center">
        <AlertCircle className="w-8 h-8 mx-auto mb-2" />
        <p className="text-lg font-semibold mb-2">
          {language === 'en' ? 'Error Loading Videos' : 'Khalad ayaa dhacay'}
        </p>
        <p className="text-sm">{error}</p>
      </div>
    </div>
  );

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <div className="flex items-center justify-center gap-4 mb-4">
            <Youtube className="w-8 h-8 text-red-600" />
            <h2 className="text-4xl font-bold">
              {language === 'en' ? 'Latest Videos' : 'Muuqaalada Ugu Dambeeyay'}
            </h2>
          </div>
          <p className="text-xl text-gray-600">
            {language === 'en'
              ? 'Watch our latest videos and stay updated'
              : 'Daawo muuqaaladeena ugu dambeeyay oo la socda'}
          </p>
        </div>

        {videos.length === 0 ? (
          <div className="text-center text-gray-500">
            {language === 'en' 
              ? 'No videos available at the moment'
              : 'Ma jiraan muuqaalo hadda'}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {videos.map((video) => {
              const embedUrl = getYouTubeEmbedUrl(video.url);
              if (!embedUrl) return null; // Skip invalid URLs
              
              return (
                <div key={video.id} className="space-y-4">
                  <div className="relative aspect-video bg-gray-100 rounded-lg overflow-hidden shadow-sm">
                    <div className="relative w-full h-0 pb-[56.25%]">
                      <iframe
                        src={embedUrl}
                        title={language === 'en' ? video.title_en : video.title_so}
                        className="absolute top-0 left-0 w-full h-full"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="font-bold text-lg mb-2">
                      {language === 'en' ? video.title_en : video.title_so}
                    </h3>
                    <p className="text-gray-600">
                      {language === 'en' ? video.description_en : video.description_so}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default Videos;