import { supabase } from './supabase';

// Types
export interface GalleryImage {
  id: string;
  title_en: string;
  title_so: string;
  description_en: string;
  description_so: string;
  url: string;
  created_at: string;
}

export interface Video {
  id: string;
  title_en: string;
  title_so: string;
  description_en: string;
  description_so: string;
  url: string;
  created_at: string;
}

export interface Event {
  id: string;
  title_en: string;
  title_so: string;
  description_en: string;
  description_so: string;
  date: string;
  created_at: string;
}

export interface Service {
  id: string;
  title_en: string;
  title_so: string;
  description_en: string;
  description_so: string;
  icon: string;
  created_at: string;
}

export interface Contact {
  id: string;
  email: string;
  message: string;
  created_at: string;
}

// Gallery functions
export const fetchGalleryImages = async (): Promise<GalleryImage[]> => {
  const { data, error } = await supabase
    .from('gallery')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
};

export const createGalleryImage = async (image: Omit<GalleryImage, 'id' | 'created_at'>): Promise<GalleryImage> => {
  const { data, error } = await supabase
    .from('gallery')
    .insert([image])
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const updateGalleryImage = async (id: string, image: Partial<GalleryImage>): Promise<GalleryImage> => {
  const { data, error } = await supabase
    .from('gallery')
    .update(image)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const deleteGalleryImage = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('gallery')
    .delete()
    .eq('id', id);

  if (error) throw error;
};

// Video functions
export const fetchVideos = async (): Promise<Video[]> => {
  const { data, error } = await supabase
    .from('videos')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
};

export const createVideo = async (video: Omit<Video, 'id' | 'created_at'>): Promise<Video> => {
  const { data, error } = await supabase
    .from('videos')
    .insert([video])
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const updateVideo = async (id: string, video: Partial<Video>): Promise<Video> => {
  const { data, error } = await supabase
    .from('videos')
    .update(video)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const deleteVideo = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('videos')
    .delete()
    .eq('id', id);

  if (error) throw error;
};

// Event functions
export const fetchEvents = async (): Promise<Event[]> => {
  const { data, error } = await supabase
    .from('events')
    .select('*')
    .order('date', { ascending: true });

  if (error) throw error;
  return data;
};

export const createEvent = async (event: Omit<Event, 'id' | 'created_at'>): Promise<Event> => {
  const { data, error } = await supabase
    .from('events')
    .insert([event])
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const updateEvent = async (id: string, event: Partial<Event>): Promise<Event> => {
  const { data, error } = await supabase
    .from('events')
    .update(event)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const deleteEvent = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('events')
    .delete()
    .eq('id', id);

  if (error) throw error;
};

// Service functions
export const fetchServices = async (): Promise<Service[]> => {
  const { data, error } = await supabase
    .from('services')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
};

export const createService = async (service: Omit<Service, 'id' | 'created_at'>): Promise<Service> => {
  const { data, error } = await supabase
    .from('services')
    .insert([service])
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const updateService = async (id: string, service: Partial<Service>): Promise<Service> => {
  const { data, error } = await supabase
    .from('services')
    .update(service)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const deleteService = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('services')
    .delete()
    .eq('id', id);

  if (error) throw error;
};

// Contact functions
export const fetchContacts = async (): Promise<Contact[]> => {
  const { data, error } = await supabase
    .from('contacts')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
};

export const createContact = async (contact: Omit<Contact, 'id' | 'created_at'>): Promise<Contact> => {
  const { data, error } = await supabase
    .from('contacts')
    .insert([contact])
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const deleteContact = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('contacts')
    .delete()
    .eq('id', id);

  if (error) throw error;
};