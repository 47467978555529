export function formatDate(date: Date | string, language: 'en' | 'so'): string {
  const d = new Date(date);
  
  if (language === 'en') {
    return d.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
  
  // Somali date format
  return d.toLocaleDateString('so-SO', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function formatTime(time: string, language: 'en' | 'so'): string {
  const date = new Date(`1970-01-01T${time}`);
  
  if (language === 'en') {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  }
  
  // Somali time format (24-hour)
  return date.toLocaleTimeString('so-SO', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

export function formatDateTime(date: Date | string, time: string, language: 'en' | 'so'): string {
  return `${formatDate(date, language)} ${formatTime(time, language)}`;
}
