import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { EventsManager } from './EventsManager';
import { ServicesManager } from './ServicesManager';
import { GalleryManager } from './GalleryManager';
import { VideosManager } from './VideosManager';
import { ContactManager } from './ContactManager';
import { Menu, X, ChevronDown } from 'lucide-react';

interface DashboardProps {
  language?: 'en' | 'so';
}

type Tab = 'events' | 'services' | 'gallery' | 'videos' | 'contacts';

export function Dashboard({ language = 'en' }: DashboardProps) {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [activeTab, setActiveTab] = useState<Tab>('events');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileTabsOpen, setIsMobileTabsOpen] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/admin/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'events':
        return <EventsManager language={language} />;
      case 'services':
        return <ServicesManager language={language} />;
      case 'gallery':
        return <GalleryManager language={language} />;
      case 'videos':
        return <VideosManager language={language} />;
      case 'contacts':
        return <ContactManager language={language} />;
      default:
        return null;
    }
  };

  const tabs = [
    { id: 'events', label: language === 'en' ? 'Events' : 'Dhacdooyinka' },
    { id: 'services', label: language === 'en' ? 'Services' : 'Adeegyada' },
    { id: 'gallery', label: language === 'en' ? 'Gallery' : 'Gallery-ga' },
    { id: 'videos', label: language === 'en' ? 'Videos' : 'Muuqaalada' },
    { id: 'contacts', label: language === 'en' ? 'Contact Requests' : 'Codsiyada Xiriirka' }
  ] as const;

  const handleTabChange = (id: Tab) => {
    setActiveTab(id);
    setIsMobileTabsOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden" onClick={() => setIsMobileMenuOpen(false)} />
      )}

      <nav className="bg-white shadow relative z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <button
                className="lg:hidden -ml-2 mr-2 p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-xl font-bold text-gray-800">
                  {language === 'en' ? 'Admin Dashboard' : 'Bogga Maamulka'}
                </h1>
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={handleSignOut}
                className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {language === 'en' ? 'Sign Out' : 'Ka Bax'}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation Drawer */}
      <div
        className={`fixed inset-y-0 left-0 transform ${
          isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 bg-white shadow-lg transition-transform duration-300 ease-in-out lg:hidden z-40`}
      >
        <div className="h-16 flex items-center justify-center border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            {language === 'en' ? 'Menu' : 'Menu-ga'}
          </h2>
        </div>
        <nav className="mt-4">
          {tabs.map(({ id, label }) => (
            <button
              key={id}
              onClick={() => {
                handleTabChange(id as Tab);
                setIsMobileMenuOpen(false);
              }}
              className={`
                w-full text-left px-4 py-3 text-sm font-medium
                ${activeTab === id
                  ? 'text-blue-600 bg-blue-50'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                }
              `}
            >
              {label}
            </button>
          ))}
        </nav>
      </div>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Desktop Tabs */}
        <div className="hidden lg:block border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map(({ id, label }) => (
              <button
                key={id}
                onClick={() => handleTabChange(id as Tab)}
                className={`
                  whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                  ${activeTab === id
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }
                `}
              >
                {label}
              </button>
            ))}
          </nav>
        </div>

        {/* Mobile Dropdown */}
        <div className="lg:hidden relative">
          <button
            onClick={() => setIsMobileTabsOpen(!isMobileTabsOpen)}
            className="w-full mt-4 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <div className="flex items-center justify-between">
              <span>{tabs.find(tab => tab.id === activeTab)?.label}</span>
              <ChevronDown className={`ml-2 h-5 w-5 transform transition-transform ${isMobileTabsOpen ? 'rotate-180' : ''}`} />
            </div>
          </button>

          {isMobileTabsOpen && (
            <div className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1">
              {tabs.map(({ id, label }) => (
                <button
                  key={id}
                  onClick={() => handleTabChange(id as Tab)}
                  className={`
                    w-full text-left px-4 py-2 text-sm
                    ${activeTab === id
                      ? 'text-blue-600 bg-blue-50'
                      : 'text-gray-700 hover:bg-gray-50'
                    }
                  `}
                >
                  {label}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="mt-6">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
}