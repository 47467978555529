import React from 'react';
import { Hero } from '../components/Hero';
import { About } from '../components/About';
import { Services } from '../components/Services';
import Videos from '../components/Videos';
import { CallToAction } from '../components/CallToAction';
import { Events } from '../components/Events';

interface HomeProps {
  language: 'en' | 'so';
}

export function Home({ language }: HomeProps) {
  return (
    <div>
      <Hero language={language} />
      <About language={language} />
      <Services language={language} />
      <Videos language={language} />
      <Events language={language} />
      <CallToAction language={language} />
    </div>
  );
}