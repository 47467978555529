import React from 'react';
import { BookOpen, Users, Gavel, FileText, Scale, Users2, Megaphone, UserPlus, MessagesSquare, Languages, BellRing, HelpCircle } from 'lucide-react';

interface Service {
  id: string;
  title: {
    en: string;
    so: string;
  };
  description: {
    en: string;
    so: string;
  };
  icon: string;
}

interface ServicesProps {
  language: 'en' | 'so';
}

const iconMap: { [key: string]: React.ElementType } = {
  BookOpen,
  Users,
  Gavel,
  FileText,
  Scale,
  Users2,
  Megaphone,
  UserPlus,
  MessagesSquare,
  Languages,
  BellRing,
  HelpCircle
};

const services: Service[] = [
  {
    id: '1',
    title: {
      en: 'Research',
      so: 'Cilmibaaris'
    },
    description: {
      en: 'Research focused on national and international law, analyzing the role of each law in the country\'s development.',
      so: 'Daraasaadka Cilmibaarista waxaanu xooga kusaaraynaa, arrimaha shuruucda dalka iyo kuwa caalamiga ah, waxaan Cilmibaaris heerkeedu sareeyo ku sameyn doonaa Doorka sharci kasto uu ka qaadan karo hormarka dalka.'
    },
    icon: 'BookOpen'
  },
  {
    id: '2',
    title: {
      en: 'Training',
      so: 'Tababaro'
    },
    description: {
      en: 'High-quality training programs designed to develop practical legal skills.',
      so: 'Tababarada aynu qabanayno waa kuwo tayadoodu sarayso, waxaanu rabnaa Sharciqaan waxa uuyaqaanno si dhaba ugu shaqeyn kara, si taa loo helo taas ayaanu Tababaro la mahdiyo diyarinayay.'
    },
    icon: 'Users'
  },
  {
    id: '3',
    title: {
      en: 'Court Simulation',
      so: 'Malataad Maxakameed'
    },
    description: {
      en: 'Practice court sessions for training in legal procedures before real court appearances.',
      so: 'Waa Maxakmad lagu baraatikaynayo Sharcgii la soo bartay intaan Maxkamada caadiga ah la tagin, si qofka doodaha iyo diyaarinta dacwadaha loo sii baro, iyadoo loo diyaarinayo Hab xeer ilaalin hab qareen iyo hab Garsoornimaba.'
    },
    icon: 'Gavel'
  },
  {
    id: '4',
    title: {
      en: 'Legal Documentation',
      so: 'Docomentari-yo Sharci ah'
    },
    description: {
      en: 'Comprehensive collection of national laws and legal history.',
      so: 'Waa Keyd Sooyaal kaas oo lagu ururinayo dhamaan shuruucda dalka ee kala duwan, iyo Shakhsiyaadka waqti dheer sharcigu ku dhex jiray.'
    },
    icon: 'FileText'
  },
  {
    id: '5',
    title: {
      en: 'Law Analysis',
      so: 'Gorfeynta shuruucda'
    },
    description: {
      en: 'Analysis and interpretation of laws to make them accessible to the public.',
      so: 'Waa falanqaynta, gorfeynta iyo faaqidaada shuruucda, si bulshadu ufahmaan sharuucida dalkooda.'
    },
    icon: 'Scale'
  },
  {
    id: '6',
    title: {
      en: 'Legal Conferences',
      so: 'Shirarka Sharciga'
    },
    description: {
      en: 'Conferences addressing legal matters and their implications.',
      so: 'Waa shirar laga arrinsanayo, arimaha sharciga iyadoo la eegaya galdalooladiisa iyo sarayta sharcigaba.'
    },
    icon: 'Users2'
  },
  {
    id: '7',
    title: {
      en: 'Legal Awareness',
      so: 'Wacyigalinta Sharciga'
    },
    description: {
      en: 'Programs bringing legal education to workplaces and homes.',
      so: 'Waa barnaamij utaagan in qofkasto goobtiisa shaqo ama gurigisa sharciga loogu geeyo lana baro.'
    },
    icon: 'Megaphone'
  },
  {
    id: '8',
    title: {
      en: 'Legal Meetings',
      so: 'Kulamada Sharciga'
    },
    description: {
      en: 'Networking events for legal professionals to discuss current and future legal trends.',
      so: 'Waa kulamo ay isku arkayaan bahda sharciga ee kala duwan iyagoon iska xog waraysanaya Halka uu marayo hanaan socodka sharciga, Iyagoo waliba odorosaya mustaqbalka Sharciga dalka iyo kan Caalamkaba.'
    },
    icon: 'MessagesSquare'
  },
  {
    id: '9',
    title: {
      en: 'Legal Interviews',
      so: 'Waraysiyada Sharciga'
    },
    description: {
      en: 'Interviews with legal experts on national and international law.',
      so: 'Wa barnaamij lagu waraysanayo xogna looga qaadayo, khubaro kala duwan kuwaas oo xeel dheeraayaal ku ah arrimaha Sharciga dalka iyo kan Caalimiga ahba.'
    },
    icon: 'UserPlus'
  },
  {
    id: '10',
    title: {
      en: 'Legal Translation',
      so: 'Turjumaada sharciga'
    },
    description: {
      en: 'Translation of legal documents and materials for the Somali community.',
      so: 'Waa barnaamij lagu Turjumayo shuruucdeena asalkeeda ama luuqadihii hore ee lagu qoray, Waxaa kalo barnaamij lagu turjumayaa maqalaada, buugta Sharciga ah, ee afnaca uleh bulshada Soomaaliyeed.'
    },
    icon: 'Languages'
  },
  {
    id: '11',
    title: {
      en: 'Legal Campaign',
      so: 'Ololaba Sharciga'
    },
    description: {
      en: 'Nationwide campaign for legal education and awareness.',
      so: 'Waa barnaamij dhig ama dhigo, oo macnaheedu yahay ama sharciga dadka bar ama baro, kaas oo Oo gobolada dalka oo dhan laga sameyn doono.'
    },
    icon: 'BellRing'
  }
];

export function Services({ language }: ServicesProps) {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">
            {language === 'en' ? 'Our Services' : 'ADEEGYADEENNA'}
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            {language === 'en' 
              ? 'Comprehensive legal solutions tailored to your needs' 
              : 'Xalal sharci oo dhammaystiran oo ku habboon baahiyahaaga'}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service) => {
            const Icon = iconMap[service.icon] || BookOpen;
            return (
              <div 
                key={service.id} 
                className="group bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 transform hover:-translate-y-1 border border-gray-100"
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="p-3 rounded-lg bg-blue-50 text-blue-600 group-hover:bg-blue-100 transition-colors">
                    <Icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-xl font-bold">{service.title[language]}</h3>
                </div>
                <p className="text-gray-600 leading-relaxed">
                  {service.description[language]}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}