import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ToastProvider } from './contexts/ToastContext';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Services } from './pages/Services';
import { Events } from './pages/Events';
import { Gallery } from './pages/Gallery';
import { LanguageToggle } from './components/LanguageToggle';
import { Login } from './pages/admin/Login';
import { Dashboard } from './pages/admin/Dashboard';
import { RequireAuth } from './components/RequireAuth';
import { SkipLink } from './components/SkipLink';
import './styles/accessibility.css';

function App() {
  const [language, setLanguage] = useState<'en' | 'so'>('en');

  return (
    <ErrorBoundary>
      <ToastProvider>
        <AuthProvider>
          <Router>
            <div className="app">
              <SkipLink language={language} />
              <Navbar language={language} />
              <LanguageToggle language={language} setLanguage={setLanguage} />
              <main id="main" className="flex-grow" tabIndex={-1}>
                <Routes>
                  <Route path="/" element={<Home language={language} />} />
                  <Route path="/about" element={<About language={language} />} />
                  <Route path="/contact" element={<Contact language={language} />} />
                  <Route path="/services" element={<Services language={language} />} />
                  <Route path="/events" element={<Events language={language} />} />
                  <Route path="/gallery" element={<Gallery language={language} />} />
                  <Route path="/admin/login" element={<Login language={language} />} />
                  <Route
                    path="/admin/*"
                    element={
                      <RequireAuth>
                        <Dashboard language={language} />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </main>
              <Footer language={language} />
            </div>
          </Router>
        </AuthProvider>
      </ToastProvider>
    </ErrorBoundary>
  );
}

export default App;