import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Mail, Phone, MapPin } from 'lucide-react';

interface FooterProps {
  language: 'en' | 'so';
}

const content = {
  en: {
    about: 'About Arji International',
    aboutText: 'A premier international law firm dedicated to providing exceptional legal services worldwide.',
    quickLinks: 'Quick Links',
    contact: 'Contact',
    rights: 'All rights reserved',
  },
  so: {
    about: 'Ku Saabsan Arji International',
    aboutText: 'Xafiis qareenno caalami ah oo u heellan bixinta adeegyo sharci oo heer sare ah.',
    quickLinks: 'Xiriiriyeyaal Degdeg ah',
    contact: 'Xiriir',
    rights: 'Xuquuqda dhowran',
  },
};

export function Footer({ language }: FooterProps) {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* About */}
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <img src="/ooo-06.png" alt="Logo" className="w-10 h-10" />
              <span className="font-bold text-xl text-white">ARJI</span>
            </div>
            <p className="text-sm">{content[language].aboutText}</p>
            <div className="flex gap-4">
              <a href="https://www.facebook.com/Arjiinternationalcompany" className="hover:text-white transition-colors">
                <Facebook className="w-5 h-5" />
              </a>
              <a href="https://x.com/garsooracademy" className="hover:text-white transition-colors">
                <Twitter className="w-5 h-5" />
              </a>
              <a href="#" className="hover:text-white transition-colors">
                <Instagram className="w-5 h-5" />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="font-bold text-white mb-4">
              {content[language].quickLinks}
            </h3>
            <div className="space-y-2">
              <Link to="/about" className="block hover:text-white transition-colors">
                {language === 'en' ? 'About Us' : 'Nagu Saabsan'}
              </Link>
              <Link to="/services" className="block hover:text-white transition-colors">
                {language === 'en' ? 'Services' : 'Adeegyada'}
              </Link>
              <Link to="/events" className="block hover:text-white transition-colors">
                {language === 'en' ? 'Events' : 'Dhacdooyinka'}
              </Link>
              <Link to="/gallery" className="block hover:text-white transition-colors">
                {language === 'en' ? 'Gallery' : 'Sawirro'}
              </Link>
            </div>
          </div>

          {/* Contact */}
          <div>
            <h3 className="font-bold text-white mb-4">
              {content[language].contact}
            </h3>
            <div className="space-y-2">
              <a href="mailto:admin@arjiinternational.com" className="flex items-center gap-2 hover:text-white transition-colors">
                <Mail className="w-4 h-4" />
                admin@arjiinternational.com
              </a>
              <a href="tel:+252617696837" className="flex items-center gap-2 hover:text-white transition-colors">
                <Phone className="w-4 h-4" />
                +252 61 769 6837
              </a>
              <p className="flex items-start gap-2">
                <MapPin className="w-4 h-4 mt-1" />
                Maka Al-Mukarama Road, Hodan District, Mogadishu
              </p>
            </div>
          </div>

          {/* Map */}
          <div className="rounded-lg overflow-hidden h-48 bg-gray-800">
            {/* Map placeholder */}
            <div className="w-full h-full flex items-center justify-center">
              <MapPin className="w-8 h-8" />
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8 text-center">
          <p> {new Date().getFullYear()} Arji International. {content[language].rights}.</p>
        </div>
      </div>
    </footer>
  );
}