import React from 'react';

interface SkipLinkProps {
  language: 'en' | 'so';
}

const translations = {
  en: {
    skipToContent: 'Skip to main content'
  },
  so: {
    skipToContent: 'U bood qaybta ugu muhiimsan'
  }
};

export function SkipLink({ language }: SkipLinkProps) {
  const t = translations[language];

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const main = document.querySelector('main');
    if (main) {
      main.tabIndex = -1;
      main.focus();
      // Remove tabIndex after focus to prevent outline
      setTimeout(() => {
        main.removeAttribute('tabIndex');
      }, 1000);
    }
  };

  return (
    <a
      href="#main"
      className="skip-to-content"
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClick(e as any);
        }
      }}
    >
      {t.skipToContent}
    </a>
  );
}
