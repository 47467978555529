import React, { useState, useEffect } from 'react';
import { PlusCircle, Edit2, Trash2 } from 'lucide-react';
import { fetchEvents, createEvent, updateEvent, deleteEvent, ApiEvent } from '../../lib/api';

interface EventsManagerProps {
  language: 'en' | 'so';
}

export function EventsManager({ language }: EventsManagerProps) {
  const [events, setEvents] = useState<ApiEvent[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Partial<ApiEvent> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = async () => {
    try {
      setLoading(true);
      setError(null);
      const eventsData = await fetchEvents();
      setEvents(eventsData.sort((a, b) => 
        new Date(b.date).getTime() - new Date(a.date).getTime()
      ));
    } catch (error) {
      console.error('Error loading events:', error);
      setError(language === 'en' 
        ? 'Failed to load events. Please try again later.'
        : 'Waa laga fursan waayey in la soo dejiyo dhacdooyinka. Fadlan isku day mar kale.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentEvent) return;

    try {
      setError(null);
      const eventData = {
        title_en: currentEvent.title_en || '',
        title_so: currentEvent.title_so || '',
        description_en: currentEvent.description_en || '',
        description_so: currentEvent.description_so || '',
        date: currentEvent.date || new Date().toISOString()
      };

      if (currentEvent.id) {
        await updateEvent(currentEvent.id, eventData);
      } else {
        await createEvent(eventData);
      }
      await loadEvents();
      setIsEditing(false);
      setCurrentEvent(null);
    } catch (error) {
      console.error('Error saving event:', error);
      setError(language === 'en'
        ? 'Failed to save event. Please try again.'
        : 'Waa laga fursan waayey in la kaydiyo dhacdada. Fadlan isku day mar kale.');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm(language === 'en' 
      ? 'Are you sure you want to delete this event?' 
      : 'Ma hubtaa inaad rabto inaad tirtirto dhacdadan?')) {
      return;
    }

    try {
      setError(null);
      await deleteEvent(id);
      await loadEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
      setError(language === 'en'
        ? 'Failed to delete event. Please try again.'
        : 'Waa laga fursan waayey in la tirtiro dhacdada. Fadlan isku day mar kale.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">
          {language === 'en' ? 'Manage Events' : 'Maamul Dhacdooyinka'}
        </h1>
        <button
          onClick={() => {
            setCurrentEvent({});
            setIsEditing(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          <PlusCircle className="w-5 h-5 mr-2" />
          {language === 'en' ? 'Add Event' : 'Kudar Dhacdo'}
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {isEditing ? (
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Title (English)' : 'Cinwaanka (English)'}
            </label>
            <input
              type="text"
              value={currentEvent?.title_en || ''}
              onChange={(e) => setCurrentEvent({ ...currentEvent, title_en: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Title (Somali)' : 'Cinwaanka (Soomaali)'}
            </label>
            <input
              type="text"
              value={currentEvent?.title_so || ''}
              onChange={(e) => setCurrentEvent({ ...currentEvent, title_so: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Description (English)' : 'Sharaxaada (English)'}
            </label>
            <textarea
              value={currentEvent?.description_en || ''}
              onChange={(e) => setCurrentEvent({ ...currentEvent, description_en: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows={4}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Description (Somali)' : 'Sharaxaada (Soomaali)'}
            </label>
            <textarea
              value={currentEvent?.description_so || ''}
              onChange={(e) => setCurrentEvent({ ...currentEvent, description_so: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows={4}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Date' : 'Taariikhda'}
            </label>
            <input
              type="datetime-local"
              value={currentEvent?.date || ''}
              onChange={(e) => setCurrentEvent({ ...currentEvent, date: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {language === 'en' ? 'Save' : 'Kaydi'}
            </button>
            <button
              type="button"
              onClick={() => {
                setIsEditing(false);
                setCurrentEvent(null);
              }}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {language === 'en' ? 'Cancel' : 'Jooji'}
            </button>
          </div>
        </form>
      ) : (
        <div className="grid grid-cols-1 gap-6">
          {events.map((event) => (
            <div key={event.id} className="bg-white shadow-md rounded-lg p-6">
              <div className="flex justify-between items-start">
                <div>
                  <h2 className="text-xl font-semibold mb-2">
                    {language === 'en' ? event.title_en : event.title_so}
                  </h2>
                  <p className="text-gray-600 mb-4">
                    {language === 'en' ? event.description_en : event.description_so}
                  </p>
                  <div className="text-sm text-gray-500">
                    <p>{new Date(event.date).toLocaleString()}</p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      setCurrentEvent(event);
                      setIsEditing(true);
                    }}
                    className="p-2 text-blue-500 hover:text-blue-700"
                  >
                    <Edit2 className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(event.id)}
                    className="p-2 text-red-500 hover:text-red-700"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}