import React from 'react';
import { Services as ServicesComponent } from '../components/Services';
import { CallToAction } from '../components/CallToAction';

interface ServicesProps {
  language: 'en' | 'so';
}

export function Services({ language }: ServicesProps) {
  return (
    <div>
      <ServicesComponent language={language} />
      <CallToAction language={language} />
    </div>
  );
}