import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchGalleryImages, createGalleryImage, updateGalleryImage, deleteGalleryImage, GalleryImage } from '../../lib/api';
import { uploadImage, getImagePublicUrl, supabase } from '../../lib/supabase';
import { Edit2, Trash2 } from 'lucide-react';

interface GalleryManagerProps {
  language: 'en' | 'so';
}

export function GalleryManager({ language }: GalleryManagerProps) {
  const [images, setImages] = useState<GalleryImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingImage, setEditingImage] = useState<GalleryImage | null>(null);

  useEffect(() => {
    loadImages();
  }, []);

  async function loadImages() {
    try {
      setLoading(true);
      const data = await fetchGalleryImages();
      setImages(data);
    } catch (error) {
      console.error('Error loading images:', error);
      setError(language === 'en' 
        ? 'Failed to load images. Please try again later.'
        : 'Waa laga fursan waayey in la soo dejiyo sawirrada. Fadlan isku day mar kale.');
    } finally {
      setLoading(false);
    }
  }

  async function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    try {
      setError(null);
      setLoading(true);

      const file = event.target.files[0];
      const { path } = await uploadImage(file);
      const url = getImagePublicUrl(path);

      const newImage = await createGalleryImage({
        title_en: file.name,
        title_so: file.name,
        description_en: '',
        description_so: '',
        url: path // Store only the path, not the full URL
      });

      setEditingImage(newImage);
      await loadImages();
    } catch (error) {
      console.error('Error uploading image:', error);
      setError(language === 'en'
        ? 'Failed to upload image. Please try again.'
        : 'Waa laga fursan waayey in la soo galiyo sawirka. Fadlan isku day mar kale.');
    } finally {
      setLoading(false);
    }
  }

  async function handleDelete(id: string) {
    if (!window.confirm(language === 'en'
      ? 'Are you sure you want to delete this image?'
      : 'Ma hubtaa inaad rabto inaad tirtirto sawirkan?')) {
      return;
    }

    try {
      setError(null);
      await deleteGalleryImage(id);
      await loadImages();
    } catch (error) {
      console.error('Error deleting image:', error);
      setError(language === 'en'
        ? 'Failed to delete image. Please try again.'
        : 'Waa laga fursan waayey in la tirtiro sawirka. Fadlan isku day mar kale.');
    }
  }

  async function handleUpdate(image: GalleryImage) {
    try {
      setError(null);
      await updateGalleryImage(image.id, image);
      setEditingImage(null);
      await loadImages();
    } catch (error) {
      console.error('Error updating image:', error);
      setError(language === 'en'
        ? 'Failed to update image. Please try again.'
        : 'Waa laga fursan waayey in la cusboonaysiiyo sawirka. Fadlan isku day mar kale.');
    }
  }

  // Function to get the full public URL for an image
  const getImageUrl = (path: string) => {
    const { data } = supabase.storage.from('images').getPublicUrl(path);
    return data?.publicUrl || '';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">
          {language === 'en' ? 'Manage Gallery' : 'Maamul Gallery-ga'}
        </h1>
        <label className="cursor-pointer px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center gap-2">
          <input
            type="file"
            className="hidden"
            accept="image/*"
            onChange={handleUpload}
          />
          {language === 'en' ? 'Upload Image' : 'Soo Gali Sawir'}
        </label>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {images.map((image) => (
          <div key={image.id} className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="relative group">
              <img
                src={getImageUrl(image.url)}
                alt={language === 'en' ? image.title_en : image.title_so}
                className="w-full h-64 object-contain bg-gray-100"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = '/placeholder-image.png'; // Add a placeholder image
                  target.onerror = null; // Prevent infinite loop
                }}
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity flex items-center justify-center opacity-0 group-hover:opacity-100">
                <div className="flex gap-4">
                  <button
                    onClick={() => setEditingImage(image)}
                    className="p-2 bg-white rounded-full hover:bg-gray-100"
                    title={language === 'en' ? 'Edit' : 'Wax ka bedel'}
                  >
                    <Edit2 className="w-5 h-5 text-blue-600" />
                  </button>
                  <button
                    onClick={() => handleDelete(image.id)}
                    className="p-2 bg-white rounded-full hover:bg-gray-100"
                    title={language === 'en' ? 'Delete' : 'Tirtir'}
                  >
                    <Trash2 className="w-5 h-5 text-red-600" />
                  </button>
                </div>
              </div>
            </div>
            {editingImage?.id === image.id ? (
              <div className="p-4">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {language === 'en' ? 'Title (English)' : 'Cinwaanka (English)'}
                    </label>
                    <input
                      type="text"
                      value={editingImage.title_en}
                      onChange={(e) => setEditingImage({ ...editingImage, title_en: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {language === 'en' ? 'Title (Somali)' : 'Cinwaanka (Soomaali)'}
                    </label>
                    <input
                      type="text"
                      value={editingImage.title_so}
                      onChange={(e) => setEditingImage({ ...editingImage, title_so: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {language === 'en' ? 'Description (English)' : 'Sharaxaada (English)'}
                    </label>
                    <textarea
                      value={editingImage.description_en}
                      onChange={(e) => setEditingImage({ ...editingImage, description_en: e.target.value })}
                      rows={2}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {language === 'en' ? 'Description (Somali)' : 'Sharaxaada (Soomaali)'}
                    </label>
                    <textarea
                      value={editingImage.description_so}
                      onChange={(e) => setEditingImage({ ...editingImage, description_so: e.target.value })}
                      rows={2}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => setEditingImage(null)}
                      className="px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                      {language === 'en' ? 'Cancel' : 'Jooji'}
                    </button>
                    <button
                      onClick={() => handleUpdate(editingImage)}
                      className="px-3 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                    >
                      {language === 'en' ? 'Save' : 'Kaydi'}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-4">
                <h2 className="text-lg font-semibold text-gray-900 mb-1">
                  {language === 'en' ? image.title_en : image.title_so}
                </h2>
                <p className="text-sm text-gray-600">
                  {language === 'en' ? image.description_en : image.description_so}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}