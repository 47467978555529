import React, { useState, useEffect } from 'react';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { useToast } from '../contexts/ToastContext';
import { fetchGalleryImages } from '../lib/api';
import { getImagePublicUrl } from '../lib/supabase';

interface GalleryProps {
  language: 'en' | 'so';
}

interface GalleryItem {
  id: string;
  title_en: string;
  title_so: string;
  description_en: string;
  description_so: string;
  url: string;
}

export function Gallery({ language }: GalleryProps) {
  const [items, setItems] = useState<GalleryItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { showToast } = useToast();

  useEffect(() => {
    const loadGalleryItems = async () => {
      try {
        setLoading(true);
        const galleryData = await fetchGalleryImages();
        console.log('Gallery Data:', galleryData);
        setItems(galleryData);
        setError(null);
      } catch (err) {
        console.error('Error loading gallery items:', err);
        setError(language === 'en' 
          ? 'Failed to load gallery items' 
          : 'Waa laga fursan waayey inaan soo bandhigno sawirrada');
        showToast(
          language === 'en' 
            ? 'Failed to load gallery items' 
            : 'Waa laga fursan waayey inaan soo bandhigno sawirrada',
          'error'
        );
      } finally {
        setLoading(false);
      }
    };

    loadGalleryItems();
  }, [language, showToast]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            {language === 'en' ? 'Try Again' : 'Isku day mar kale'}
          </button>
        </div>
      </div>
    );
  }

  const getLocalizedText = (en: string, so: string) => {
    return language === 'en' ? en : so;
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        {language === 'en' ? 'Gallery' : 'Gallery'}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {items.map(item => (
          <div
            key={item.id}
            className="bg-white rounded-lg shadow-md overflow-hidden transition-transform hover:scale-105"
          >
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={getImagePublicUrl(item.url)}
                alt={getLocalizedText(item.title_en, item.title_so)}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">
                {getLocalizedText(item.title_en, item.title_so)}
              </h3>
              <p className="text-gray-600">
                {getLocalizedText(item.description_en, item.description_so)}
              </p>
            </div>
          </div>
        ))}
      </div>
      {items.length === 0 && (
        <div className="text-center text-gray-500 mt-8">
          {language === 'en' 
            ? 'No images available in the gallery' 
            : 'Ma jiraan sawirro lagu bandhigo'}
        </div>
      )}
    </div>
  );
}