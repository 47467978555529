import React from 'react';
import { CallToAction } from '../components/CallToAction';
import { Mail, Phone, MapPin, Clock, Globe2 } from 'lucide-react';

interface ContactProps {
  language: 'en' | 'so';
}

const content = {
  en: {
    title: 'Contact Us',
    subtitle: 'Get in touch with our legal experts',
    office: 'Main Office',
    address: 'Maka Al-Mukarama Road, Hodan District, Mogadishu',
    email: 'info@arji.com',
    phone: '+252 61 234 5678',
    hours: 'Working Hours',
    hoursDetail: 'Monday - Friday: 8:00 AM - 6:00 PM',
    weekend: 'Saturday: 9:00 AM - 2:00 PM',
    languages: 'Languages',
    languagesSpoken: 'Somali, English, Arabic',
  },
  so: {
    title: 'Nala Soo Xiriir',
    subtitle: 'La xiriir khubaradeena sharciga',
    office: 'Xafiiska Dhexe',
    address: 'Wadada Maka Al-Mukarama, Degmada Hodan, Muqdisho',
    email: 'info@arji.com',
    phone: '+252 61 234 5678',
    hours: 'Saacadaha Shaqada',
    hoursDetail: 'Isniin - Jimce: 8:00 AM - 6:00 PM',
    weekend: 'Sabti: 9:00 AM - 2:00 PM',
    languages: 'Luuqadaha',
    languagesSpoken: 'Soomaali, Ingiriisi, Carabi',
  }
};

export function Contact({ language }: ContactProps) {
  return (
    <div className="py-20">
      <div className="container mx-auto px-6">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h1 className="text-4xl font-bold mb-4">{content[language].title}</h1>
          <p className="text-xl text-gray-600">{content[language].subtitle}</p>
        </div>

        <div className="grid md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div>
            <CallToAction language={language} />
          </div>

          {/* Contact Details Card */}
          <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
            {/* Office Details */}
            <div>
              <h3 className="text-xl font-bold mb-4 flex items-center gap-2">
                <MapPin className="w-5 h-5 text-blue-600" />
                {content[language].office}
              </h3>
              <p className="text-gray-600 ml-7">{content[language].address}</p>
            </div>

            {/* Contact Information */}
            <div className="space-y-3">
              <a href={`mailto:${content[language].email}`} className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors">
                <Mail className="w-5 h-5" />
                {content[language].email}
              </a>
              <a href={`tel:${content[language].phone}`} className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors">
                <Phone className="w-5 h-5" />
                {content[language].phone}
              </a>
            </div>

            {/* Working Hours */}
            <div>
              <h3 className="text-xl font-bold mb-4 flex items-center gap-2">
                <Clock className="w-5 h-5 text-blue-600" />
                {content[language].hours}
              </h3>
              <div className="space-y-2 ml-7 text-gray-600">
                <p>{content[language].hoursDetail}</p>
                <p>{content[language].weekend}</p>
              </div>
            </div>

            {/* Languages */}
            <div>
              <h3 className="text-xl font-bold mb-4 flex items-center gap-2">
                <Globe2 className="w-5 h-5 text-blue-600" />
                {content[language].languages}
              </h3>
              <p className="text-gray-600 ml-7">{content[language].languagesSpoken}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}