import React, { useState, useEffect } from 'react';
import { Calendar, MapPin, Clock } from 'lucide-react';
import { fetchEvents, Event } from '../lib/api';

interface EventsProps {
  language: 'en' | 'so';
}

const Events: React.FC<EventsProps> = ({ language }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const data = await fetchEvents();
        setEvents(data);
        setLoading(false);
      } catch (err) {
        console.error('Error loading events:', err);
        setError(err instanceof Error ? err.message : 'Failed to load events');
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  if (loading) return (
    <div className="flex justify-center items-center min-h-[400px]">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
  
  if (error) return (
    <div className="flex justify-center items-center min-h-[400px] text-red-500">
      <div className="text-center">
        <p className="text-lg font-semibold mb-2">
          {language === 'en' ? 'Error Loading Events' : 'Khalad ayaa dhacay'}
        </p>
        <p className="text-sm">{error}</p>
      </div>
    </div>
  );

  return (
    <div className="py-20">
      <div className="container mx-auto px-6">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h1 className="text-4xl font-bold mb-4">
            {language === 'en' ? 'Upcoming Events' : 'Dhacdooyinka Soo Socda'}
          </h1>
          <p className="text-xl text-gray-600">
            {language === 'en'
              ? 'Join us in our upcoming legal events and workshops'
              : 'Nagu soo biir dhacdooyinkeena sharciga iyo tababarada soo socda'}
          </p>
        </div>

        {events.length === 0 ? (
          <div className="text-center text-gray-500">
            {language === 'en' 
              ? 'No upcoming events at the moment'
              : 'Ma jiraan dhacdooyin hadda socda'}
          </div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {events.map((event) => (
              <div key={event.id} className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-xl font-semibold mb-4">
                  {language === 'en' ? event.title_en : event.title_so}
                </h3>
                <div className="flex items-center mb-2">
                  <Calendar className="w-5 h-5 mr-2" />
                  <span>{event.date}</span>
                </div>
                <div className="flex items-center mb-2">
                  <Clock className="w-5 h-5 mr-2" />
                  <span>{event.time}</span>
                </div>
                <div className="flex items-center mb-4">
                  <MapPin className="w-5 h-5 mr-2" />
                  <span>{language === 'en' ? event.location_en : event.location_so}</span>
                </div>
                <p className="text-gray-600">
                  {language === 'en' ? event.description_en : event.description_so}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { Events };