import React, { useState, useEffect } from 'react';
import { PlusCircle, Edit2, Trash2 } from 'lucide-react';
import { fetchServices, createService, updateService, deleteService, ApiService } from '../../lib/api';

interface ServicesManagerProps {
  language: 'en' | 'so';
}

export function ServicesManager({ language }: ServicesManagerProps) {
  const [services, setServices] = useState<ApiService[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentService, setCurrentService] = useState<Partial<ApiService> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadServices();
  }, []);

  const loadServices = async () => {
    try {
      setLoading(true);
      setError(null);
      const servicesData = await fetchServices();
      setServices(servicesData);
    } catch (error) {
      console.error('Error loading services:', error);
      setError(language === 'en'
        ? 'Failed to load services. Please try again later.'
        : 'Waa laga fursan waayey in la soo dejiyo adeegyada. Fadlan isku day mar kale.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentService) return;

    try {
      setError(null);
      if (currentService.id) {
        await updateService(currentService.id, currentService);
      } else {
        await createService(currentService as Omit<ApiService, 'id'>);
      }
      await loadServices();
      setIsEditing(false);
      setCurrentService(null);
    } catch (error) {
      console.error('Error saving service:', error);
      setError(language === 'en'
        ? 'Failed to save service. Please try again.'
        : 'Waa laga fursan waayey in la kaydiyo adeega. Fadlan isku day mar kale.');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm(language === 'en'
      ? 'Are you sure you want to delete this service?'
      : 'Ma hubtaa inaad rabto inaad tirtirto adeegan?')) {
      return;
    }

    try {
      setError(null);
      await deleteService(id);
      await loadServices();
    } catch (error) {
      console.error('Error deleting service:', error);
      setError(language === 'en'
        ? 'Failed to delete service. Please try again.'
        : 'Waa laga fursan waayey in la tirtiro adeega. Fadlan isku day mar kale.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">
          {language === 'en' ? 'Manage Services' : 'Maamul Adeegyada'}
        </h1>
        <button
          onClick={() => {
            setCurrentService({});
            setIsEditing(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          <PlusCircle className="w-5 h-5 mr-2" />
          {language === 'en' ? 'Add Service' : 'Kudar Adeeg'}
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {isEditing ? (
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Title (English)' : 'Cinwaanka (English)'}
            </label>
            <input
              type="text"
              value={currentService?.title_en || ''}
              onChange={(e) => setCurrentService({ ...currentService, title_en: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Title (Somali)' : 'Cinwaanka (Soomaali)'}
            </label>
            <input
              type="text"
              value={currentService?.title_so || ''}
              onChange={(e) => setCurrentService({ ...currentService, title_so: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Description (English)' : 'Sharaxaada (English)'}
            </label>
            <textarea
              value={currentService?.description_en || ''}
              onChange={(e) => setCurrentService({ ...currentService, description_en: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows={4}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Description (Somali)' : 'Sharaxaada (Soomaali)'}
            </label>
            <textarea
              value={currentService?.description_so || ''}
              onChange={(e) => setCurrentService({ ...currentService, description_so: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows={4}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {language === 'en' ? 'Icon' : 'Icon-ka'}
            </label>
            <input
              type="text"
              value={currentService?.icon || ''}
              onChange={(e) => setCurrentService({ ...currentService, icon: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {language === 'en' ? 'Save' : 'Kaydi'}
            </button>
            <button
              type="button"
              onClick={() => {
                setIsEditing(false);
                setCurrentService(null);
              }}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {language === 'en' ? 'Cancel' : 'Jooji'}
            </button>
          </div>
        </form>
      ) : (
        <div className="grid grid-cols-1 gap-6">
          {services.map((service) => (
            <div key={service.id} className="bg-white shadow-md rounded-lg p-6">
              <div className="flex justify-between items-start">
                <div>
                  <h2 className="text-xl font-semibold mb-2">
                    {language === 'en' ? service.title_en : service.title_so}
                  </h2>
                  <p className="text-gray-600 mb-4">
                    {language === 'en' ? service.description_en : service.description_so}
                  </p>
                  <div className="text-sm text-gray-500">
                    <p>{service.icon}</p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      setCurrentService(service);
                      setIsEditing(true);
                    }}
                    className="p-2 text-blue-500 hover:text-blue-700"
                  >
                    <Edit2 className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(service.id)}
                    className="p-2 text-red-500 hover:text-red-700"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}