import React from 'react';
import { Globe2 } from 'lucide-react';

interface LanguageToggleProps {
  language: 'en' | 'so';
  setLanguage: (lang: 'en' | 'so') => void;
}

export function LanguageToggle({ language, setLanguage }: LanguageToggleProps) {
  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        onClick={() => setLanguage(language === 'en' ? 'so' : 'en')}
        className="group bg-white/90 backdrop-blur-sm px-4 py-2.5 rounded-full shadow-lg flex items-center gap-2 hover:bg-white transition-all duration-300 border border-gray-200/50 hover:border-gray-300"
      >
        <Globe2 className="w-5 h-5 text-blue-600 group-hover:rotate-180 transition-transform duration-500" />
        <span className="font-medium text-gray-800">
          {language === 'en' ? 'Soomaali' : 'English'}
        </span>
      </button>
    </div>
  );
}